/**
 * Get only projects where current user is listed under Project roles
 */
export default {
  methods: {
    // return only projects where current user is listed under Project roles
    usersProjects () {
      let myProjects = []
      // console.log(' in usersProjects')
      if (this.$store.state.bend.organization.projects !== undefined) {
        const allProjects = this.$store.state.bend.organization.projects
        if (allProjects.length > 0) {
          allProjects.forEach(p => {
              // console.log(u.id)
              if (p.myrole !== 'none') {
                // console.log(u)
                myProjects = myProjects.concat(p)
              }
          })
        }
      }
      // console.log(myProjects)
      return myProjects
    },

    userType (useruuid) {
      // console.log(useruuid)
      const org = this.$store.state.bend.organization
      const selectedUser = org.allStaff.filter(s => s.uuid === useruuid)
      // console.log(selectedUser)
      return selectedUser[0].type
    },

    userIndex (useruuid) {
      // console.log(useruuid)
      const org = this.$store.state.bend.organization
      const index = org.allStaff.findIndex(x => x.uuid === useruuid)
      // console.log(index)
      return index
    },

    // return all other staff except current user
    otherStaff () {
      const org = this.$store.state.bend.organization
      const leftUsers = org.allStaff.filter(s => s.uuid !== org.currentStaff[0].uuid)
      // console.log(leftUsers)
      return leftUsers
    },

    // is Current user is owner of the project
    isOwner () {
      const org = this.$store.state.bend.organization
      const owner = ('owneruid' in org) ? org.owneruid : 'NA'
      const staff = org.currentStaff[0].uuid
      return owner === staff
    },

    // is Current user is VIP client e.g. Enterprise user
    isUda () {
      const arr = this.$store.state.bend.organization.currentStaff[0].roles
      return arr.some((o) => o.label === 'uda')
      // const obj = arr.find(o => o.label === 'vip')
      // return obj
      // return this.$store.state.bend.organization.currentStaff[0].type === 'vip'
    },

    // is Current user is VIP client e.g. Enterprise user
    isVip () {
      const arr = this.$store.state.bend.organization.currentStaff[0].roles
      return arr.some((o) => o.label === 'vip')
      // const obj = arr.find(o => o.label === 'vip')
      // return obj
      // return this.$store.state.bend.organization.currentStaff[0].type === 'vip'
    },

    // is Current user is client
    isClient () {
      return this.$store.state.bend.organization.currentStaff[0].type === 'client'
    },

    // current staff's project role
    myRole () {
      return this.$store.state.main.ap.myrole
    },

    // current user's type
    myUserType () {
      return this.$store.state.bend.organization.currentStaff[0].type
    },

    myAccessLevel () {
      const access = `${this.myRole()}-${this.isClient() ? 'client' : 'staff'}`
      return access
    },

    // Used in Account Settings page to change the password
    async changePassword () {
      // Get valid token
      await this.$store.dispatch('auth/getToken')

      const data = {
        _links: { type: { href: `${this.$store.state.auth.baseUrl}/rest/type/user/user` } },
        pass: [{ existing: this.currentPass, value: this.newPass }],
      }
      return new Promise(() => {
        this.$httpBend
          .patch(`/user/${this.$store.state.auth.user.uid}?_format=json`, data, {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
              Accept: 'application/json',
              'Content-Type': 'application/hal+json',
            },
          })
          .then(response => {
            // console.log(response)
            if (response.status === 200) {
              this.$router.push({ name: 'Logout' })
            }
          })
          .catch(error => {
            this.snackbar = true
            this.snackbarType = 'error'
            this.snackbarText = error.response.data.message
            // console.log(error.response)
            // reject(error)
          })
      })
    },

    async getVipClient () {
      // Get valid token
      await this.$store.dispatch('auth/getToken')
          // STEP 1. Get Organization
      // console.log('get-organization')
      // {server}/api/organization
      return new Promise((resolve, reject) => {
        this.$httpBend
          .get('api/vip', {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
          })
          .then(async response => {
            // console.log(response)

            // if there is any VIP Client, respose data length is more than 0
            if (response.data.length > 0) {
              this.vipClient = response.data
            }

            // // console.log(response)
            return resolve(false)
          })
          .catch(() => {
            return resolve(false)
          })
      })
    },
  },
}
